import React, { useState, useEffect } from "react";
import moment from "moment";
import bg from './assets/bg.jpg';
import './App.css';

const m = moment("2020-12-25");

const leftPad = (str) => {
  if (str.length === 1) {
    return `0${str}`;
  }

  if (str.length === 0) {
    return "00";
  }

  return str;
};

const getTimeLeft = () => {
  const dur = moment.duration(m.diff(moment()));
  return `${dur.months()} months ${dur.days()} days ${leftPad(
    dur.hours().toString()
  )}:${leftPad(dur.minutes().toString())}:${leftPad(dur.seconds().toString())}`;
};

export default function App() {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
  }, []);

  return (
    <div className="container">
      <div
        className="image"
        style={{backgroundImage: `url(${bg})`}}
      ></div>
      <div className="text">Xbox Series X</div>
      <div className="countdown">{timeLeft}</div>
    </div>
  );
}
